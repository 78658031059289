import React, { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import {
    ProfileIcon,
    SettingsIcon,
    SessionIcon,
    LogoutIcon,
    AdminIcon,
    ActivityIcon,
    MonitorIcon,
    WarningIcon
} from '@docpace/shared-react-icons'
import adminRoutes from '@docpace/shared-react-routes/adminRoutes'
import { AdminHeaderSearch } from '@docpace/admin-react-components'
import NavbarContainer from '@docpace/shared-react-components/NavbarContainer'

const logoImage = '/static/horizontal_white_2x.png'

export interface AdminHeaderProps {
    children?: any
    title?: string
    subtitle?: string
    handleLogout: () => void
    isProd: boolean
}

export const AdminHeaderFilter: FC<any> = () => {
    return <button>Filter</button>
}

const liClassName = 'flex justify-start space-x-4'
const liLinkClassName = 'flex justify-start'
const linkTitleClassName = 'ml-4'

export const AdminHeader: FC<AdminHeaderProps> = (props) => {
    const router = useRouter()
    const { children, title, subtitle, handleLogout, isProd } = props

    return (
        <NavbarContainer>
            <div className="pt-1 flex flex-col items-start pl-2">
                <Link 
                    href={adminRoutes.Home.href()} 
                    className='flex items-end text-white no-underline'
                >
                    <img
                        alt=""
                        src={logoImage }
                        draggable={false}
                        className={clsx('select-none h-6', title && 'h-5')}
                    />
                    {title && (
                        <div className="uppercase m-0 font-bold text-lg ml-2">
                            {title}
                        </div>
                    )}
                </Link>
                <div className="flex justify-start pt-1">
                    {subtitle && (
                        <span className="text-white text-sm">{subtitle}</span>
                    )}
                </div>
            </div>

            <div className="flex items-center items-stretch">
                {children}

                <AdminHeaderSearch />

                <div className="dropdown dropdown-end">
                    <div tabIndex={1} className="btn text-xl btn-primary">
                        <SettingsIcon />
                    </div>
                    <ul
                        tabIndex={1}
                        className="dropdown-content p-0 shadow menu bg-base-100 rounded w-52 z-99"
                        style={{ width: 300 }}
                    >
                        <li className={liClassName}>
                            <Link href={adminRoutes.AdminAdminIndex.href()} className={liLinkClassName}>
                                <AdminIcon />
                                <div className={linkTitleClassName}>
                                    Admins
                                </div>
                            </Link>
                        </li>
                        <li className={liClassName}>
                            <Link href={adminRoutes.MonitorStateDurations.href()} className={liLinkClassName}>
                                <MonitorIcon />
                                <div className={linkTitleClassName}>
                                    Monitors
                                </div>
                            </Link>
                        </li>
                        <li className={liClassName}>
                            <Link href={adminRoutes.Warnings.href()} className={liLinkClassName}>
                                <WarningIcon />
                                <div className={linkTitleClassName}>
                                    Dashboard Warnings
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="dropdown dropdown-end">
                    <div tabIndex={2} className="btn text-xl btn-primary">
                        <ProfileIcon />
                    </div>
                    <ul
                        tabIndex={2}
                        className="dropdown-content p-0 shadow menu bg-base-100 rounded w-52 z-99"
                        style={{ width: 300 }}
                    >
                        <li className={liClassName}>
                            <Link href={adminRoutes.AdminSessionIndex.href()} className={liLinkClassName}>
                                <SessionIcon />
                                <div className={linkTitleClassName}>
                                    Session
                                </div>
                            </Link>
                        </li>
                        <li onClick={handleLogout} className={liClassName}>
                            <Link href='#' className={liLinkClassName}>
                                <div><LogoutIcon /></div>
                                <div className={linkTitleClassName}>
                                    Log Out
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </NavbarContainer>
    )
}
