import React, { FC } from 'react'
import {
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    SelectProps,
} from '@material-ui/core'
import { toString } from 'lodash'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

export interface MuiFormSelectProps extends SelectProps {
    formController?: any
}

export const MuiFormSelect: FC<MuiFormSelectProps> = ({
    name,
    label,
    defaultValue,
    children,
    required = false,
    fullWidth,
    formController,
    onChange: propsOnChange,
    ...selectProps
}) => {
    const labelId = `${name}-label`
    const _formContext = useFormContext()
    const formContext = formController ?? _formContext
    const {
        control,
        register,
        formState: { errors },
        setValue,
    } = formContext
    const _watch = useWatch({ name, control, defaultValue })
    const value = name ? _watch : null
    const thisError = errors[name]
    const defaultOnChange = (ev) => {
        setValue(name, ev?.target?.value)
    }
    return (
        <FormControl required={required} fullWidth={fullWidth}>
            <InputLabel id={labelId} required={required} children={label} />
            <Select
                labelId={labelId}
                label={label}
                required={required}
                fullWidth={fullWidth}
                {...selectProps}
                {...register(name)}
                defaultValue={defaultValue !== null ? defaultValue : ''}
                value={value !== null ? value : ''}
                onChange={propsOnChange ?? defaultOnChange}
            >
                {children}
            </Select>
            <FormHelperText error={!!thisError} children={toString(thisError?.message)} />
        </FormControl>
    )
}

interface MuiFormSelectUncontrolledProps {
    errorMessage?: string
}

export const MuiFormSelectUncontrolled: FC<
    MuiFormSelectUncontrolledProps & SelectProps
> = ({
    name,
    label,
    defaultValue,
    children,
    required = false,
    error = null,
    errorMessage = null,
    ...props
}) => {
    const labelId = `${name}-label`

    return (
        <FormControl required={required}>
            <InputLabel id={labelId} required={required} children={label} />
            <Select
                labelId={labelId}
                label={label}
                required={required}
                {...props}
            >
                {children}
            </Select>
            <FormHelperText error={!!error} children={toString(errorMessage)} />
        </FormControl>
    )
}
