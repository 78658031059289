import React, { FC } from 'react'
import * as yup from 'yup'
import { Button } from '@docpace/shared-react-components/Button'

import { useMuiFormStyles } from '@docpace/shared-react-hooks/useMuiFormStyles'
import {
    useReactHookForm,
    UseReactHookFormProps,
} from '@docpace/shared-react-hooks/useReactHookForm'
import {
    IndeterminateCheckbox,
} from '@docpace/shared-react-components/IndeterminateCheckbox'
import { useWatch } from 'react-hook-form'

export interface AdminUpdateItemTristateFormProps
    extends Omit<UseReactHookFormProps, 'schema'> {
    name: string
    title?: string
    saveOnChange?: boolean
    indeterminateOnNull?: boolean
    onCancel?: () => any
}

export const AdminUpdateItemTristateForm: FC<AdminUpdateItemTristateFormProps> = (
    props
) => {
    const {
        onCancel,
        name,
        title,
        saveOnChange,
        indeterminateOnNull = true,
        ...reactHookProps
    } = props
    const schema = {
        [name]: yup.boolean().nullable(),
    }
    const { FormWrapper, formController, submitError, doSubmit } =
        useReactHookForm({
            ...reactHookProps,
            schema,
        })
    const classes = useMuiFormStyles()

    const { getValues, setValue, control } = formController
    const watchedValue = useWatch({ control, name })

    const defaultValue = reactHookProps?.defaultValues?.[name]

    const isDefaultValue =
        typeof defaultValue !== 'undefined' &&
        typeof defaultValue === typeof watchedValue &&
        defaultValue === watchedValue

    return (
        <FormWrapper formComponentProps={{ autoComplete: 'off' }}>
            <div
                className={classes.vertical}
            >
                <div>
                    <div className='inline-block' onClick={async () => {
                            if (indeterminateOnNull && watchedValue === false) {
                                setValue(name, null)
                            } else {
                                setValue(name, !watchedValue)
                            }
                            saveOnChange && await doSubmit(getValues())
                        }}>
                    <IndeterminateCheckbox
                        indeterminateOnNull={indeterminateOnNull}
                        isChecked={watchedValue}
                    />
                    </div>
                </div>
                { !saveOnChange && <div className="space-x-2">
                    <Button
                        children="Save"
                        type="submit"
                        className='btn-primary'
                        variant="contained"
                    />
                    <Button
                        children="Cancel"
                        variant="outlined"
                        onClick={() => onCancel()}
                    />
                </div> }
                {/* <MuiFormSubmitControls onCancel={onCancel} submitError={submitError} /> */}
            </div>
        </FormWrapper>
    )
}
