import React, { FC, useMemo } from 'react'
import { TextField } from '@material-ui/core'

import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { toString, toNumber } from 'lodash'
import clsx from 'clsx'

interface FormTextField {
    name: string
    label?: string
    formControlStyle?: any
    className?: string
    formController?: any
    type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'search' | 'url' | 'color' | 'date' | 'time' | 'month' | 'range' | 'week'
    defaultValue?: any
    fullWidth?: boolean
    multiline?: boolean
    disabled?: boolean
    readOnly?: boolean
    nullable?: boolean
    placeholder?: string
    color?: 'primary' | 'secondary'
}

export const FormTextField: FC<
    FormTextField
> = ({
    readOnly = false,
    nullable,
    fullWidth = true,
    formController,
    formControlStyle,
    ...textFieldProps
}) => {
    const { name, defaultValue, label, type } = textFieldProps
    const labelId = `${name}-label`
    const _formContext = useFormContext()
    const formContext = formController ?? _formContext
    const {
        control,
        register,
        setValue,
        formState: { errors },
    } = formContext
    const thisError = errors[name]
    return (
            <div
                // className={clsx("standard-input", fullWidth && 'w-full')}
                style={formControlStyle}
            >
                <Controller
                    render={({ field }) => (<>
                        {/* todo implement fullWidth */}
                        {/* <div className="standard-input">
                            <input 
                                type="text" 
                                name={name}
                                placeholder={textFieldProps?.placeholder ?? ''}
                                value={field.value ?? ''}
                                {...textFieldProps}
                                {...field}
                                onChange={(ev) => {
                                    const val = ev.target.value
                                    if (nullable) {
                                        setValue(
                                            field.name,
                                            val === '' ? null : val
                                            )
                                        } else {
                                            setValue(field.name, val)
                                        }
                                }}
                                
                            />
                            <label htmlFor={name} id={labelId}>{ label }</label>
                        </div> */}
                        {/* <input className='outlined-input' type='text' name='username'/> */}
                        <TextField
                            // variant={textFieldProps?.variant}
                            {...field}
                            {...textFieldProps}
                            className={clsx(textFieldProps?.className, fullWidth && 'w-full')}
                        />
                    </>)}
                    // inputRef={register}
                    control={control}
                    name={name}
                    aria-label={toString(label)}
                    label={label}
                    // error={!!thisError}
                    {...textFieldProps}
                />
                {/* <div style={{ top: -4 }} className="border-b border-gray-200 relative top--2" /> */}
                <div className='text-red-500 h-4'>
                    { toString(thisError?.message)}
                </div>
            </div>
        // </ThemeProvider>
    )
}
