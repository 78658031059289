import React, { FC } from 'react'
import { isNull, isUndefined } from 'lodash'
import clsx from 'clsx'

import {
    CheckboxChecked,
    Checkbox,
    CheckboxMinus,
} from '@docpace/shared-react-icons'

interface IndeterminateCheckboxProps {
    isChecked: boolean
    indeterminateOnNull?: boolean
    showBgColor?: boolean
    dimBgColor?: boolean
    onClick?: () => Promise<void>
}

export const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({
    isChecked,
    indeterminateOnNull = false,
    showBgColor = true,
    dimBgColor = false,
}) => {
    const defaultDivClassName = 'flex h-7 w-7'
    const defaultIconClassName = 'h-7 w-7 rounded-md'
    const checkedIconColor = 'bg-emerald-200 dark:bg-docpace-dark'
    const minusIconColor = 'bg-red-200 dark:bg-red-800'

    return (
        <div className={clsx(defaultDivClassName, dimBgColor && 'opacity-30')}>
            {isChecked === true && (
                <CheckboxChecked
                    className={clsx(
                        defaultIconClassName,
                        showBgColor && checkedIconColor
                    )}
                />
            )}

            {(isChecked === false ||
                (!indeterminateOnNull &&
                    (isNull(isChecked) || isUndefined(isChecked)))) && (
                <CheckboxMinus
                    className={clsx(
                        defaultIconClassName,
                        showBgColor && minusIconColor
                    )}
                />
            )}

            {(isChecked === null || isChecked === undefined) &&
                indeterminateOnNull && (
                    <Checkbox className={clsx(defaultIconClassName)} />
                )}
        </div>
    )
}
