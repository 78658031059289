import React, { useState, FC, useEffect } from 'react'
import { debounce } from 'lodash'
import { Backdrop, CircularProgress, FormHelperText } from '@material-ui/core'
import { Button } from '@docpace/shared-react-components/Button'
import { UseMuiLoadingIndicatorProps } from './'


interface LoadingIndicatorProps {
    children?: any
}

export const useMuiLoadingIndicator = (
    props: UseMuiLoadingIndicatorProps = {}
): [
    boolean,
    (isLoading: boolean) => void,
    FC,
    (error: string | Error) => void
] => {
    const { onClose } = props
    const [isLoading, setIsLoading] = useState<boolean>(
        props.isLoading ? props.isLoading : false
    )
    const setIsLoadingDebounced = debounce(setIsLoading, 250) // if timeout changed, also update MuiFormSubmitControls to match
    const [error, setErrorString] = useState<string | null>(null)

    useEffect(() => {
        setIsLoadingDebounced(props.isLoading ? props.isLoading : false)
    }, [props.isLoading])

    const doClose = () => {
        if (typeof onClose === 'function') onClose()
    }

    const handleClose = () => {
        setIsLoading(false)
        setErrorString(null)
        doClose()
    }

    const handleError = (err: string | Error) => {
        if (typeof err === 'string') {
            setErrorString(err)
        } else if (typeof err === 'object') {
            setErrorString(err?.message)
        } else {
            setErrorString('there was a problem')
        }
    }

    const LoadingIndicator = ({ children }: LoadingIndicatorProps) => {
        const _children = children ? children : () => <></>
        return (
            <>
                {_children}
                <Backdrop open={isLoading} style={{ zIndex: 5000, margin: 0 }}>
                    {error ? (
                        <div
                            style={{
                                fontSize: '1.15rem',
                                backgroundColor: 'white',
                                padding: '0.5em',
                                borderRadius: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                maxWidth: 600,
                            }}
                        >
                            <FormHelperText
                                error
                                style={{ marginBottom: '0.5em' }}
                            >
                                {error}
                            </FormHelperText>
                            <Button
                                color="primary"
                                onClick={handleClose}
                                variant="outlined"
                            >
                                Close
                            </Button>
                        </div>
                    ) : (
                        <CircularProgress
                            color="secondary"
                            style={{ height: '2em', width: '2em' }}
                        />
                    )}
                </Backdrop>
            </>
        )
    }

    return [isLoading, setIsLoading, LoadingIndicator, handleError]
}

export default useMuiLoadingIndicator
