import React, { FC } from 'react'
import { MenuItem } from '@docpace/shared-react-components/Menu'
import { filter } from 'lodash'
import {
    MuiFormSelect,
    MuiFormSelectProps,
} from '@docpace/shared-react-components/MuiFormSelect'
import { TranslationFragment } from '@docpace/shared-graphql/fragments'
import { useFormContext, useWatch } from 'react-hook-form'
import { TranslationType } from '@docpace/shared-ts-types'

interface MuiFormTranslationSelectProps extends MuiFormSelectProps {
    formController?: any
    translations: TranslationFragment[]
    translationType: TranslationType
    label?: string
}

export const MuiFormTranslationSelect: FC<MuiFormTranslationSelectProps> = ({
    formController,
    translations,
    translationType,
    ...selectProps
}) => {
    const { name, defaultValue } = selectProps
    const formContext = useFormContext()
    const {
        control,
        formState: { errors },
    } = formContext
    return (
        <MuiFormSelect {...selectProps}>
            <MenuItem value={''} children={'none'} />
            {filter(translations, { translationType })?.map((translation) => {
                return (
                    <MenuItem
                        key={translation?.translationId}
                        value={translation?.translationId}
                    >
                        {translation?.name}
                    </MenuItem>
                )
            })}
        </MuiFormSelect>
    )
}

export default MuiFormTranslationSelect
