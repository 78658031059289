import React, { useState } from 'react'
import { useAtom } from 'jotai'
import {
    clone,
    compact,
    find,
    filter,
    first,
    remove,
    pick,
    isNumber,
} from 'lodash'
import Bluebird from 'bluebird'

import {
    useAdminCreateProviderDepartmentOptionMutation,
    useAdminPracticeProviderDepartmentOptionsQuery,
    useAdminDeleteProviderDepartmentOptionMutation,
    useAdminUpdateProviderDepartmentOptionMutation,
} from '@docpace/admin-react-apollo'
import {
    ProviderDepartmentOptionFragment,
    ProviderDepartmentOptionNoIdFragment,
} from '@docpace/shared-graphql/fragments'
import {
    ProviderDepartmentOptionItem,
    filterByApptTypeProviderDepartmentId,
    AppointmentCycleStatsType,
    ApptTypeProviderDepartmentIdInterface,
} from '@docpace/shared-ts-types'
import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'

const fields = [
    'appointmentTypeId',
    'departmentId',
    'providerId',
    ...Object.keys(ProviderDepartmentOptionItem),
]

interface UseProviderDepartmentOptionsProps {
    practiceId: string
}

export interface UseProviderDepartmentOptionsOutput {
    practiceDefaultOption: ProviderDepartmentOptionFragment | null
    providerDepartmentOptions: ProviderDepartmentOptionFragment[]
    setOption: (
        ids: ApptTypeProviderDepartmentIdInterface,
        optKey: ProviderDepartmentOptionItem,
        value?: any
    ) => void
    getOption: (
        ids: ApptTypeProviderDepartmentIdInterface
    ) => ProviderDepartmentOptionNoIdFragment | null
    getModifiedOption: (
        ids: ApptTypeProviderDepartmentIdInterface
    ) => ProviderDepartmentOptionNoIdFragment | null
    getProviderDepartmentOptionByIds: (
        ids: ApptTypeProviderDepartmentIdInterface
    ) => ProviderDepartmentOptionFragment | null
    isLoading: boolean
    handleSubmit: () => Promise<void>
    handleCancel: () => Promise<void>
    refetchProviderDepartmentOptions: () => Promise<any>
    isSubmitting: boolean
    hasModifications: boolean
    updateProviderDepartmentOption: (
        options?: any
    ) => Promise<any>
    createProviderDepartmentOption: (
        options?: any
    ) => Promise<any>
    deleteProviderDepartmentOption: (
        options?: any
    ) => Promise<any>
}

const getOptByIds: (
    providerDepartmentOptions: ProviderDepartmentOptionFragment[],
    ids: ApptTypeProviderDepartmentIdInterface
) => ProviderDepartmentOptionFragment = (providerDepartmentOptions, ids) => {
    return providerDepartmentOptions?.filter((opt) =>
        filterByApptTypeProviderDepartmentId(opt, ids)
    )?.[0]
}

export const useProviderDepartmentOptions: (
    props?: UseProviderDepartmentOptionsProps
) => UseProviderDepartmentOptionsOutput = (props) => {
    const [optionsToAdd, setOptionsToAdd] = useState<
        ProviderDepartmentOptionNoIdFragment[]
    >([])
    const [optionsToUpdate, setOptionsToUpdate] = useState<
        ProviderDepartmentOptionFragment[]
    >([])
    const [ ctxPracticeId ] = useAtom(ctxPracticeIdAtom)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const practiceId = props?.practiceId ?? ctxPracticeId
    const { data, loading, refetch } =
        useAdminPracticeProviderDepartmentOptionsQuery({
            variables: { practiceId },
            skip: !practiceId,
        })

    const [deleteProviderDepartmentOption] =
        useAdminDeleteProviderDepartmentOptionMutation()
    const [createProviderDepartmentOption] =
        useAdminCreateProviderDepartmentOptionMutation()
    const [updateProviderDepartmentOption] =
        useAdminUpdateProviderDepartmentOptionMutation()

    const providerDepartmentOptions: any[] = compact(
        data?.providerDepartmentOptions?.nodes
    )

    const practiceDefaultOption =
        find(providerDepartmentOptions, {
            appointmentTypeId: null,
            departmentId: null,
            providerId: null,
            practiceId
        }) ?? null

    const getOption: (
        ids: ApptTypeProviderDepartmentIdInterface
    ) => ProviderDepartmentOptionFragment | null = (ids) => {
        const res =
            filter(providerDepartmentOptions, ids).filter(
                (r) => !isNumber(r)
            )?.[0] ?? null
        return {
            practiceId,
            providerDepartmentOptionId: -1,
            statsType: AppointmentCycleStatsType.PRACTICE,
            ...(typeof res === 'object' ? res : {}),
        }
    }

    const getModifiedOption = (ids: ApptTypeProviderDepartmentIdInterface) => {
        const res =
            first(
                compact([find(optionsToAdd, ids), find(optionsToUpdate, ids)])
            ) ?? null
        return isNumber(res) ? null : { ...res, practiceId }
    }

    const getLatestOption: (
        ids: ApptTypeProviderDepartmentIdInterface
    ) => ProviderDepartmentOptionNoIdFragment | null = (ids) => {
        const res = first(
            compact([
                find(optionsToAdd, ids),
                find(optionsToUpdate, ids),
                find(providerDepartmentOptions, ids),
            ])
        )

        return res && !isNumber(res) ? {
            statsType: res?.['statsType'],
            ...res,
            practiceId,
        } : null
    }


    const setOption = (
        ids: ApptTypeProviderDepartmentIdInterface,
        optKey: ProviderDepartmentOptionItem,
        value = undefined
    ) => {
        if (isSubmitting) return
        const existingOption = getOption(ids)

        const thisLatestOpts = getLatestOption(ids)
        const thisLatestOpt = thisLatestOpts?.[optKey]
        let newOpt: boolean | null = !thisLatestOpt
        if (thisLatestOpt === false) {
            newOpt = null
        }
        if (existingOption) {
            const opts = clone(optionsToUpdate)
            if (ids) {
                remove(opts, { ...ids, practiceId })
            }
            setOptionsToUpdate([
                ...compact(opts),
                {
                    providerDepartmentOptionId: existingOption?.providerDepartmentOptionId,
                    statsType: AppointmentCycleStatsType.PRACTICE,
                    ...thisLatestOpts,
                    practiceId: existingOption?.practiceId ?? '',
                    [optKey]: newOpt,
                },
            ])
        } else {
            const opts = clone(optionsToAdd)
            remove(opts, { ...ids, practiceId })
            setOptionsToAdd([
                ...opts.map((r) => ({ ...r, practiceId })),
                {
                    statsType: AppointmentCycleStatsType.PRACTICE,
                    ...ids,
                    ...thisLatestOpts,
                    practiceId,
                    [optKey]: newOpt,
                },
            ])
        }
    }

    const reset = async () => {
        setOptionsToAdd([])
        setOptionsToUpdate([])
    }

    const handleCancel = async () => {
        await reset()
    }

    const handleSubmit = async () => {
        if (isSubmitting) return
        setIsSubmitting(true)
        await Bluebird.map(optionsToAdd, async (opt) => {
            await createProviderDepartmentOption({
                variables: {
                    input: {
                        providerDepartmentOption: {
                            practiceId,
                            ...pick(opt, fields),
                        },
                    },
                },
            })
        })

        await Bluebird.map(optionsToUpdate, async (opt) => {
            await updateProviderDepartmentOption({
                variables: {
                    input: {
                        providerDepartmentOptionId:
                            opt?.providerDepartmentOptionId,
                        patch: pick(opt, fields),
                    },
                },
            })
        })

        await refetch()
        await reset()
        setIsSubmitting(false)
    }

    const globalProviderDepartmentOption = getOptByIds(
        providerDepartmentOptions,
        {
            practiceId: null,
            appointmentTypeId: null,
            providerId: null,
            departmentId: null,
        }
    )?.[0]

    return {
        setOption,
        isLoading: loading || isSubmitting,
        practiceDefaultOption:
            practiceDefaultOption && !isNumber(practiceDefaultOption)
                ? practiceDefaultOption
                : null,
        providerDepartmentOptions,
        getProviderDepartmentOptionByIds: (
            ids: ApptTypeProviderDepartmentIdInterface
        ) => {
            if (ids === null) return globalProviderDepartmentOption
            const { providerId = null, departmentId = null, appointmentTypeId = null, practiceId } =
                ids
            return getOptByIds(providerDepartmentOptions, {
                practiceId,
                appointmentTypeId,
                providerId,
                departmentId,
            })
        },
        getModifiedOption,
        getOption,
        handleSubmit,
        handleCancel,
        isSubmitting,
        deleteProviderDepartmentOption,
        createProviderDepartmentOption,
        updateProviderDepartmentOption,
        hasModifications:
        optionsToAdd?.length > 0 || optionsToUpdate?.length > 0,
        refetchProviderDepartmentOptions: refetch,
    }
}

export default useProviderDepartmentOptions
