import { useQuery, ApolloQueryResult, QueryHookOptions } from '@apollo/client'
import { map, compact } from 'lodash'

import { AdminFavoritesQueryDocument } from '@docpace/admin-graphql/dist/urql.types'
import { AdminFavoritesQueryResultFragment, AdminFavoritesQueryParams } from '@docpace/admin-graphql'

export function useAdminFavoritesQuery(opts?: QueryHookOptions<AdminFavoritesQueryResultFragment, Partial<AdminFavoritesQueryParams>>){
    
    const queryResult = useQuery(AdminFavoritesQueryDocument, opts)
    const {
        adminFavoritePractices,
        adminFavoriteDepartments,
        adminFavoriteProviders,
        adminFavoriteAppointmentTypes,
    } = queryResult?.data ?? {}

    const favoritePracticeIds: string[] = map(
        compact(adminFavoritePractices?.nodes),
        'practiceId'
    )
    const favoriteDepartmentIds: string[] = map(
        compact(adminFavoriteDepartments?.nodes),
        'departmentId'
    )
    const favoriteProviderIds: string[] = map(
        compact(adminFavoriteProviders?.nodes),
        'providerId'
    )
    const favoriteAppointmentTypeIds: string[] = map(
        compact(adminFavoriteAppointmentTypes?.nodes),
        'appointmentTypeId'
    )
    
    return {
        ...queryResult,
        favoritePracticeIds,
        favoriteDepartmentIds,
        favoriteProviderIds,
        favoriteAppointmentTypeIds,
    }
}