export enum TranslationType {
    VWR = 'VWR',
    SMS = 'SMS',
    FAQ = 'FAQ',
    DIRECTIONS = 'DIRECTIONS',
    CONTACT = 'CONTACT',
    LINK = 'LINK',
}

export enum TranslationLanguage {
    eng = 'eng',
    spa = 'spa',
    por = 'por',
}

// export type Translation = {
//     translationId: string
//     practiceId?: string
//     name: string
//     translationType: TranslationType
//     overrideTranslationId?: string
//     eng: string
//     spa?: string
//     created: Date
//     updated: Date
// }