import {
    ActorOverviewFragment,
} from '@docpace/shared-graphql/fragments'
import { useAdminBase } from '@docpace/admin-react-hooks'
import { useAdminUpdateActorAdminDashboardSettingsMutation } from '@docpace/admin-react-apollo'

interface UseAdminDashboardSettingsProps {
    skipQueries?: boolean
}

interface UseAdminDashboardSettingsOutput {
    actor: ActorOverviewFragment | null
    adminDashboardSettings: any
    updateAdminDashboardSettings: (key: string, value: any) => Promise<void>
}

export const useAdminDashboardSettings: (
    props: UseAdminDashboardSettingsProps
) => UseAdminDashboardSettingsOutput = ({ skipQueries }) => {
    const { admin } = useAdminBase({
        skip: skipQueries,
    })

    const actor = admin?.adminActor
    const adminDashboardSettings = admin?.adminActor?.adminDashboardSettings

    const [updateAdminDashboardSettingsMutation] =
        useAdminUpdateActorAdminDashboardSettingsMutation()

    const updateAdminDashboardSettings = async (key: string, value: any) => {
        if (actor) {
            await updateAdminDashboardSettingsMutation({
                variables: {
                    actorId: actor.actorId ?? 0,
                    settings: {
                        ...actor?.adminDashboardSettings,
                        [key]: value,
                    },
                },
                optimisticResponse: {
                    updateActor: {
                        actor: {
                            ...actor,
                            adminDashboardSettings: {
                                ...actor?.adminDashboardSettings,
                                [key]: value,
                            },
                            // this following lines force an immediate render rather than waiting for the server response
                            ...(key === 'sidebarPinned'
                                ? { sidebarPinned: value }
                                : {}),
                        },
                    },
                },
            })
        }
    }

    return {
        actor: actor ?? null,
        adminDashboardSettings,
        updateAdminDashboardSettings,
    }
}

export default useAdminDashboardSettings
