import React, { FC } from 'react'
import { MenuItem } from '@docpace/shared-react-components/Menu'
import { MuiFormSelect, MuiFormSelectProps } from '@docpace/shared-react-components/MuiFormSelect'
import { AppointmentCycleStatsType} from '@docpace/shared-ts-types'

export const MuiFormAppointmentCycleStatsTypeSelect: FC<MuiFormSelectProps> = ({
    ...props
}) => {
    return (
        <MuiFormSelect {...props}>
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={AppointmentCycleStatsType.PRACTICE}>
                Practice
            </MenuItem>
            <MenuItem value={AppointmentCycleStatsType.PROVIDER}>
                Provider
            </MenuItem>
        </MuiFormSelect>
    )
}
