// export type Template = {
//     templateId: string
//     practiceId?: string
//     name: string
//     templateType: TemplateType
//     template: string
//     created: Moment
//     updated: Moment
// }

export enum TemplateType {
    VWR = 'VWR',
}
