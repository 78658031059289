'use client'
import React, { useState, FC, Dispatch, SetStateAction, useCallback } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { UseFormProps, UseFormReturn } from 'react-hook-form'

import { keys, pick } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.umd.js'
import * as yup from 'yup'
import { useMuiLoadingIndicator } from '@docpace/shared-react-hooks/useMuiLoadingIndicator'

export interface UseReactHookFormProps extends UseFormProps {
    data?: any
    schema: any
    onSubmit: (formValues: any, formController?: any) => Promise<any> | any
    onSubmitSuccess?: (response: any) => void
    onSubmitFailure?: (error: Error) => Promise<void> | void
    onCancel?: () => Promise<void> | void
    className?: any
}

export type ConditionalSchema<T> = T extends string
  ? yup.StringSchema
  : T extends number
  ? yup.NumberSchema
  : T extends boolean
  ? yup.BooleanSchema
  : T extends Record<any, any>
  ? yup.AnyObjectSchema
  : T extends Array<any>
  ? yup.ArraySchema<any, any>
  : yup.AnySchema;

export type FormSchemaShape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};

export interface UseReactHookFormRenderProps {
    children?: any
    formComponentProps?: any
    className?: any
}

export type FormControllerType = UseFormReturn<
    Pick<
        {
            [x: string]: any
        },
        string
    >,
    any
>

export interface UseReactHookFormHookValues {
    FormWrapper: FC<UseReactHookFormRenderProps>
    formController: FormControllerType
    resetWithData: (data) => void
    isSaving: boolean
    doSubmit: (formValues: any) => Promise<void>
    submitError: Error | null
    didSubmitFail: boolean
    didSubmitSucceed: boolean
}

export const useReactHookForm = (
    props: UseReactHookFormProps
): UseReactHookFormHookValues => {
    if (props.defaultValues) {
        props.defaultValues = pick(props.defaultValues, keys(props.schema))
    }

    const {
        schema,
        onSubmit,
        onSubmitSuccess,
        onSubmitFailure,
        onCancel,
        defaultValues,
        ...useFormProps
    } = props

    const formController: UseFormReturn = useForm({
        resolver: yupResolver(yup.object().shape(schema)),
        defaultValues: pick(defaultValues ?? {}, keys(schema)),
        ...useFormProps,
    })

    const [isSaving, setIsSaving, LoadingIndicator] = useMuiLoadingIndicator()
    const [submitError, setSubmitError] = useState<Error | null>(null)
    const [ didSubmitSucceed, setDidSubmitSucceed ] = useState<boolean>(false)
    const [ didSubmitFail, setDidSubmitFail ] = useState<boolean>(false)

    const resetWithData = (data) => {
        formController.reset(pick(data, keys(schema)), {
            keepDirty: false,
        })
    }

    const doSubmit = async (formValues) => {
        setIsSaving(true)
        setDidSubmitFail(false)
        setDidSubmitSucceed(false)
        setSubmitError(null)
        try {
            const res = await onSubmit(
                yup.object().shape(schema).cast(formValues),
                formController
            )
            setIsSaving(false)
            setDidSubmitSucceed(true)
            onSubmitSuccess && (await onSubmitSuccess(res))
        } catch (error: any) {
            setIsSaving(false)
            setSubmitError(error)
            setDidSubmitFail(true)
            onSubmitFailure && onSubmitFailure(error)
        }
    }

    const FormWrapper = useCallback((renderProps?: UseReactHookFormRenderProps) => {
        const { children, formComponentProps } = renderProps ?? {}

        return (
            <FormProvider {...formController}>
                <form
                    onSubmit={formController.handleSubmit(doSubmit)}
                    {...formComponentProps}
                    >
                    {/* <LoadingIndicator /> */}
                    {children}
                </form>
            </FormProvider>
        )
    }, [ formController ])

    return {
        FormWrapper,
        isSaving,
        resetWithData,
        formController,
        doSubmit,
        submitError,
        didSubmitFail,
        didSubmitSucceed,
    }
}