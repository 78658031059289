import React, { FC } from 'react'
import * as yup from 'yup'
import { Button } from '@docpace/shared-react-components/Button'

import { useMuiFormStyles } from '@docpace/shared-react-hooks/useMuiFormStyles'
import {
    useReactHookForm,
    UseReactHookFormProps,
} from '@docpace/shared-react-hooks/useReactHookForm'
import { FormTextField } from '@docpace/shared-react-components/FormTextField'

export interface AdminUpdateItemIntNumberFormProps
    extends Omit<UseReactHookFormProps, 'schema'> {
    name: string
    title: string
    onCancel?: () => any
}

export const AdminUpdateItemIntNumberForm: FC<AdminUpdateItemIntNumberFormProps> = (
    props
) => {
    const { onCancel, name, title, ...reactHookProps } = props
    const schema = {
        [name]: yup.number().nullable(),
    }
    const { FormWrapper, formController, submitError, doSubmit } =
        useReactHookForm({
            ...reactHookProps,
            schema,
        })
    const classes = useMuiFormStyles()

    const { getValues, setValue, control } = formController

    const defaultValue = reactHookProps?.defaultValues?.[name]

    return (
        <FormWrapper formComponentProps={{ autoComplete: 'off' }}>
            <div className={classes.vertical}>
                <div className="flex justify-between w-full">
                    <FormTextField
                        name={name}
                        defaultValue={defaultValue}
                        type="number"
                    />
                    <button
                        className="btn btn-sm w-14 btn-ghost"
                        onClick={() => {
                            setValue(name, null)
                        }}
                    >
                        Clear
                    </button>
                </div>

                <div className="space-x-2">
                    <Button
                        children="Save"
                        type="submit"
                        variant="contained"
                    />
                    <Button
                        children="Cancel"
                        variant="outlined"
                        onClick={() => onCancel()}
                    />
                </div>
                {/* <MuiFormSubmitControls onCancel={onCancel} submitError={submitError} /> */}
            </div>
        </FormWrapper>
    )
}
