import React, { FC } from 'react'
import * as yup from 'yup'
import { Button } from '@docpace/shared-react-components/Button'

import { useMuiFormStyles } from '@docpace/shared-react-hooks/useMuiFormStyles'
import {
    useReactHookForm,
    UseReactHookFormProps,
} from '@docpace/shared-react-hooks/useReactHookForm'
import {
    MuiFormPracticeSlugSelect,
} from '@docpace/shared-react-components/MuiFormPracticeSlugSelect'
import { PracticeSlugFragment } from '@docpace/shared-graphql/fragments'

export interface AdminUpdateItemPracticeSlugFormProps
    extends Omit<UseReactHookFormProps, 'schema'> {
    name: string
    title: string
    onCancel?: () => any
    practiceSlugs: PracticeSlugFragment[]
}

export const AdminUpdateItemPracticeSlugForm: FC<
    AdminUpdateItemPracticeSlugFormProps
> = (props) => {
    const {
        onCancel,
        name,
        title,
        practiceSlugs,
        ...reactHookProps
    } = props
    const schema = {
        [name]: yup.number().nullable(),
    }
    const { FormWrapper, formController, submitError, doSubmit } =
        useReactHookForm({
            ...reactHookProps,
            schema,
        })
    const classes = useMuiFormStyles()

    const { getValues, setValue } = formController

    return (
        <FormWrapper formComponentProps={{ autoComplete: 'off' }}>
            <div className={classes.vertical}>
                <MuiFormPracticeSlugSelect
                    practiceSlugs={practiceSlugs}
                    label={title}
                    name={name}
                />

                <div className="space-x-2">
                    <Button
                        children="Save"
                        type="submit"
                        variant="contained"
                    />
                    <Button
                        children="Cancel"
                        variant="outlined"
                        onClick={() => onCancel()}
                        />
                    <Button
                        children="Clear"
                        variant="outlined"
                        onClick={(ev) => {
                            setValue(name, null)
                            ev.stopPropagation()
                        }}
                    />
                </div>
                {/* <MuiFormSubmitControls onCancel={onCancel} submitError={submitError} /> */}
            </div>
        </FormWrapper>
    )
}
