import { ApolloError } from '@apollo/client'
import { useAtom } from 'jotai'

import { useAdminFavoritesQuery } from '@docpace/shared-react-apollo'
import { ctxAdminIdAtom } from '@docpace/shared-react-atoms'

interface UseAdminFavoritesProps {
    onError?: (error: ApolloError) => void
}

export function useFavorites(
    { onError }: UseAdminFavoritesProps = {}
){
    const [ adminId ] = useAtom(ctxAdminIdAtom)    
    const { 
        loading,
        refetch,
        favoriteDepartmentIds,
        favoritePracticeIds,
        favoriteProviderIds,
        favoriteAppointmentTypeIds,
    } = useAdminFavoritesQuery({
        variables: { adminId },
        skip: !adminId,
        fetchPolicy: 'cache-first',
        onError
    })

    return {
        loading,
        refetch,
        favoriteDepartmentIds,
        favoritePracticeIds,
        favoriteProviderIds,
        favoriteAppointmentTypeIds,
    }
}

export default useFavorites
