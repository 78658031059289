export interface ApptTypeProviderDepartmentIdInterface {
    appointmentTypeId?: string | null
    providerId?: string | null
    departmentId?: string | null
    practiceId?: string | null
}

export function filterByApptTypeProviderDepartmentId(
    opt: ApptTypeProviderDepartmentIdInterface,
    ids: ApptTypeProviderDepartmentIdInterface
): boolean {
    return (
        opt?.practiceId == ids?.practiceId &&
        opt?.departmentId == ids?.departmentId &&
        opt?.providerId == ids?.providerId &&
        opt?.appointmentTypeId == ids?.appointmentTypeId
    )
}
