import { makeStyles } from '@docpace/shared-react-components/makeStyles'

export const ADMIN_HEADER_HEIGHT = 40
export const ADMIN_SIDEBAR_DRAWER_WIDTH = 210
export const ADMIN_SIDEBAR_DRAWER_WIDTH_CLOSED = 48

export const useAdminSidebarStyles = makeStyles((theme) => ({
    drawer: {
        width: ADMIN_SIDEBAR_DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'relative',
        top: 0,
        height: `calc(100% - ${ADMIN_HEADER_HEIGHT}px) !important`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    drawerOpen: {
        width: ADMIN_SIDEBAR_DRAWER_WIDTH,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: '0.5s',
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeInOut,
            duration: '0.5s',
        }),
        overflowX: 'hidden',
        width: ADMIN_SIDEBAR_DRAWER_WIDTH_CLOSED,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(6) + 1,
        // },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    closeIcon: {
        position: 'relative',
        right: 6,
    },
    listSubheader: {
        height: 24,
        '& > *': {
            position: 'relative',
            top: -12,
        },
    },
}))
