import React, { FC } from 'react'
import { MenuItem } from '@docpace/shared-react-components/Menu'
import {
    MuiFormSelect,
    MuiFormSelectProps,
} from '@docpace/shared-react-components/MuiFormSelect'
import { PracticeSlugFragment } from '@docpace/shared-graphql/fragments'
import { useFormContext } from 'react-hook-form'

interface MuiFormPracticeSlugSelectProps extends MuiFormSelectProps {
    formController?: any
    practiceSlugs: PracticeSlugFragment[]
    label?: string
}

export const MuiFormPracticeSlugSelect: FC<MuiFormPracticeSlugSelectProps> = ({
    formController,
    practiceSlugs,
    ...selectProps
}) => {
    const formContext = useFormContext()
    const {
        formState: { errors },
    } = formContext
    return (
        <MuiFormSelect {...selectProps}>
            <MenuItem value={''} children={'none'} />
            { practiceSlugs?.map((slug) => {
                return (
                    <MenuItem
                        key={slug?.practiceSlugId}
                        value={slug?.practiceSlugId}
                    >
                        {slug?.slug}
                    </MenuItem>
                )
            })}
        </MuiFormSelect>
    )
}

export default MuiFormPracticeSlugSelect
