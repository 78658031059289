import React, { FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'

import { AdminLayoutProps } from '../AdminLayout'

interface AdminLayoutNavProps extends AdminLayoutProps {
    loading?: boolean
    className?: string
}

export const AdminLayoutNav: FC<AdminLayoutNavProps> = ({
    makeNav,
    loading,
    children,
    className,
}) => {
    return makeNav ? (
        <div
            className={clsx(
                'flex pr-2 pl-3 pb-0 justify-between w-full box-border items-center h-9 dark:text-white',
                className
            )}
        >
            {loading && (
                <div
                    className={clsx('text-sm', 'cursor-pointer', className)}
                    children="Loading"
                />
            )}
            {!loading && (
                <div
                    className={clsx('text-sm breadcrumbs relative', className)}
                    style={{ top: 1 }}
                >
                    <ul>
                        {makeNav().map(({ title, href, className }, i) => (
                            <li key={i} className={clsx('text-sm', className)}>
                                {href ? (
                                    <Link href={href}>{title}</Link>
                                ) : (
                                    title
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="flex space-x-1 items-center justify-end flex-1">
                {children}
            </div>
        </div>
    ) : (
        <></>
    )
}
