import React, { FC } from 'react'
import * as yup from 'yup'
import { Button } from '@docpace/shared-react-components/Button'

import { useMuiFormStyles } from '@docpace/shared-react-hooks/useMuiFormStyles'
import {
    useReactHookForm,
    UseReactHookFormProps,
} from '@docpace/shared-react-hooks/useReactHookForm'
import {
    MuiFormTranslationSelect,
} from '@docpace/shared-react-components/MuiFormTranslationSelect'
import { TranslationFragment } from '@docpace/shared-graphql/fragments'
import { TranslationType } from '@docpace/shared-ts-types'

export interface AdminUpdateItemTranslationFormProps
    extends Omit<UseReactHookFormProps, 'schema'> {
    name: string
    title: string
    translationType: TranslationType
    onCancel?: () => any
    translations: TranslationFragment[]
}

export const AdminUpdateItemTranslationForm: FC<
    AdminUpdateItemTranslationFormProps
> = (props) => {
    const {
        onCancel,
        translations,
        name,
        title,
        translationType,
        ...reactHookProps
    } = props
    const schema = {
        [name]: yup.string(),
    }
    const { FormWrapper, formController, submitError, doSubmit } =
        useReactHookForm({
            ...reactHookProps,
            schema,
        })
    const classes = useMuiFormStyles()

    const { getValues, setValue } = formController

    return (
        <FormWrapper formComponentProps={{ autoComplete: 'off' }}>
            <div className={classes.vertical}>
                <MuiFormTranslationSelect
                    translationType={translationType}
                    translations={translations}
                    label={title}
                    name={name}
                />

                <div className="space-x-2">
                    <Button
                        children="Save"
                        type="submit"
                        variant="contained"
                    />
                    <Button
                        children="Cancel"
                        variant="outlined"
                        onClick={() => onCancel()}
                    />
                </div>
                {/* <MuiFormSubmitControls onCancel={onCancel} submitError={submitError} /> */}
            </div>
        </FormWrapper>
    )
}