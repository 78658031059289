import { useQuery, QueryHookOptions } from '@apollo/client'
import { AdminBaseQueryDocument } from '@docpace/admin-graphql/dist/urql.types'
import { AdminBaseQueryResultFragment } from '@docpace/admin-graphql'
import { useAtom } from 'jotai'
import { ctxAdminIdAtom, ctxPracticeIdAtom } from '@docpace/shared-react-atoms'

export function useAdminBaseQuery(opts?: QueryHookOptions<AdminBaseQueryResultFragment>){
    return useQuery<AdminBaseQueryResultFragment>(AdminBaseQueryDocument, opts)
}

export interface UseContextAdminBaseQueryParams {
    isAuthPage?: boolean
}

export function useContextAdminBaseQuery(params?: UseContextAdminBaseQueryParams){
    
    const { isAuthPage } = params ?? {}
    const [ adminId, setCtxAdminId ] = useAtom(ctxAdminIdAtom)

    function setContextIds({ practiceId, adminId, managerId }: AdminBaseQueryResultFragment){
        setCtxAdminId(adminId ?? undefined)
    }

    const adminBaseQueryResult = useAdminBaseQuery({
        skip: isAuthPage,
        onCompleted: setContextIds,
    })
   
    return {
        ...adminBaseQueryResult,
        // prevent anything downstream in the context from receiving an admin if 
        // a manager account override is being used
        adminId, 
        serverInfo: adminBaseQueryResult?.data?.serverInfo,
    }
}