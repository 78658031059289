import { makeStyles } from '@docpace/shared-react-components/makeStyles'

interface FormStyleClasses {
    root: any
    horizontal: any
    vertical: any
    vertical80: any
}

export const useMuiFormStyles: () => FormStyleClasses = makeStyles(
    (theme: any) => {
        const root: any = {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            '& > *': {
                margin: theme.spacing(0.625),
            },
        }

        const horizontal = { ...root, flexWrap: 'wrap' }
        const vertical = { ...root, flexDirection: 'column' }

        return {
            root,
            horizontal,
            vertical,
            vertical80: {
                ...vertical,
                minHeight: '70vh',
                justifyContent: 'space-between',
            },
        }
    }
)
